import numeral from "numeral";
import dayjs from "dayjs";
var advancedFormat = require("dayjs/plugin/advancedFormat");
// import advancedFormat from 'dayjs/plugin/advancedFormat' // ES 2015

dayjs.extend(advancedFormat);

export function useFormatter() {
  function formatNumber(num) {
    return numeral(num).format("0,0");
  }
  function formatAmount(amt) {
    return numeral(amt).format("0,0.00");
  }
  function formatDateWithString(dt, format) {
    return dayjs(dt).format(format);
  }
  function amount_in_words(amount) {
    if (amount) {
      let number = parseFloat(amount).toFixed(2);
      // let number = amount.toString().slice(2).split(",").join("");
      // let number = "15981939534.00";
      let num = number.split(".");
      let whole = converter(num[0]) + " " + "Naira";
      let defaultDecimal = converter(num[1]);

      let decimal = defaultDecimal.slice(4) + " " + "Kobo";
      let value;
      if (converter(num[1]) != "zero") {
        value = whole + "," + " " + decimal;
      } else {
        value = whole + " " + "Only";
      }
      return value;
      // return converter.internationalConversion(num.toString().slice(2).replace(",",""), {characterCase: 'uppercase'});
    }
  }
  function converter(n, custom_join_character) {
    var string = n.toString(),
      units,
      tens,
      scales,
      start,
      end,
      chunks,
      chunksLen,
      chunk,
      ints,
      i,
      word,
      words;
    var and = custom_join_character || "and";

    /* Is number zero? */
    if (parseInt(string) === 0) {
      return "zero";
    }

    /* Array of units as words */
    units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];

    /* Array of tens as words */
    tens = [
      "",
      "",
      "Twenty-",
      "Thirty-",
      "Forty-",
      "Fifty-",
      "Sixty-",
      "Seventy-",
      "Eighty-",
      "Ninety-",
    ];

    /* Array of scales as words */
    scales = [
      "",
      "Thousand,",
      "Million,",
      "Billion,",
      "Trillion",
      "Quadrillion",
      "Quintillion",
      "Sextillion",
      "Septillion",
      "Octillion",
      "Nonillion",
      "Decillion",
      "Undecillion",
      "Duodecillion",
      "Tredecillion",
      "Quatttuor-Decillion",
      "Quindecillion",
      "Sexdecillion",
      "Septen-decillion",
      "Octodecillion",
      "Novemdecillion",
      "Vigintillion",
      "Centillion",
    ];

    /* Split user arguemnt into 3 digit chunks from right to left */
    start = string.length;
    chunks = [];
    while (start > 0) {
      end = start;
      chunks.push(string.slice((start = Math.max(0, start - 3)), end));
    }

    /* Check if function has enough scale words to be able to stringify the user argument */
    chunksLen = chunks.length;
    if (chunksLen > scales.length) {
      return "";
    }

    /* Stringify each integer in each chunk */
    words = [];
    for (i = 0; i < chunksLen; i++) {
      chunk = parseInt(chunks[i]);
      if (chunk) {
        /* Split chunk into array of individual integers */
        ints = chunks[i].split("").reverse().map(parseFloat);

        /* If tens integer is 1, i.e. 10, then add 10 to units integer */
        if (ints[1] === 1) {
          ints[0] += 10;
        }

        /* Add scale word if chunk is not zero and array item exists */
        if ((word = scales[i])) {
          words.push(word);
        }

        /* Add unit word if array item exists */
        if ((word = units[ints[0]])) {
          words.push(word);
        }

        /* Add tens word if array item exists */
        if ((word = tens[ints[1]])) {
          words.push(word);
        }

        /* Add 'and' string after units or tens integer if: */
        if (ints[0] || ints[1]) {
          /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
          if (ints[2] || (!i && chunksLen)) {
            words.push(and);
          }
        }

        /* Add hundreds word if array item exists */
        if ((word = units[ints[2]])) {
          words.push(word + " Hundred");
        }
      }
    }
    let initialValue = words.reverse().join(" ");
    let finalValue = initialValue.split("- ").join("-");
    return finalValue;
    // return words.reverse().join(' ');
  }
  return {
    formatAmount,
    formatNumber,
    formatDateWithString,
    amount_in_words,
  };
}
