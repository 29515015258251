<template>
  <div class="space-y-12">
    <div>
      <div
        class="text-sm md:text-lg text-gray-dark font-medium bg-gray-50 rounded mb-2 p-2 flex flex-row justify-between items-center"
      >
        <h1 class="hidden md:block">Ministries, Departments and Agencies</h1>
        <h1 class="block md:hidden">MDA</h1>

        <span class="font-semibold"
          >Total : ₦{{ formatAmount(TotalPayments) }}</span
        >
      </div>
      <apexchart
        width="100%"
        height="400px"
        type="bar"
        :options="MdaChart.options"
        :series="MdaChart.series"
      ></apexchart>
    </div>

    <div>
      <div
        class="text-sm md:text-lg text-gray-dark font-medium bg-gray-50 rounded mb-2 p-2 flex flex-row justify-between items-center"
      >
        <h1>Revenue Items</h1>

        <span class="font-semibold"
          >Total : ₦{{ formatAmount(RevenueItemsTotalPayments) }}</span
        >
      </div>
      <apexchart
        width="100%"
        height="400px"
        type="bar"
        :options="RevenueItemChart.options"
        :series="RevenueItemChart.series"
      ></apexchart>
    </div>
    <div>
      <div
        class="text-sm md:text-lg text-gray-dark font-medium bg-gray-50 rounded mb-2 p-2 flex flex-row justify-between items-center"
      >
        <h1>Collecting Banks</h1>

        <span class="font-semibold"
          >Total : ₦{{ formatAmount(BanksTotalPayments) }}</span
        >
      </div>
      <apexchart
        width="100%"
        height="400px"
        type="bar"
        :options="BanksChart.options"
        :series="BanksChart.series"
      ></apexchart>
    </div>
    <div>
      <div
        class="text-sm md:text-lg text-gray-dark font-medium bg-gray-50 rounded mb-2 p-2 flex flex-row justify-between items-center"
      >
        <h1>Channels</h1>

        <span class="font-semibold"
          >Total : ₦{{ formatAmount(ChannelsTotalPayments) }}</span
        >
      </div>
      <apexchart
        width="100%"
        height="400px"
        type="bar"
        :options="ChannelChart.options"
        :series="ChannelChart.series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import numeral from "numeral";
import { useFormatter } from "../../helpers/formatter";

const { formatAmount } = useFormatter();

export default {
  props: {
    pSearchPaymentData: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      TotalPayments: 0,
      ChannelsTotalPayments: 0,
      RevenueItemsTotalPayments: 0,
      BanksTotalPayments: 0,
      chartInterval: null,
      searchPaymentData: {},
      MdaChart: {
        options: {
          chart: {
            id: "vuechart-example",
            type: "bar",
            animations: {
              enabled: true,
              easing: "easeinout",
              speed: 800,
              animateGradually: {
                enabled: true,
                delay: 150,
              },
              dynamicAnimation: {
                enabled: true,
                speed: 350,
              },
            },
          },
          noData: {
            text: "Loading...",
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 10,
              borderRadiusApplication: "around",
              borderRadiusWhenStacked: "last",
              columnWidth: "70%",
              barHeight: "70%",
              distributed: false,
              rangeBarOverlap: true,
              rangeBarGroupRows: false,
              hideZeroBarsWhenGrouped: false,
              isDumbbell: false,
              dumbbellColors: undefined,
              isFunnel: false,
              isFunnel3d: true,
              colors: {
                ranges: [
                  {
                    from: 0,
                    to: 0,
                    color: undefined,
                  },
                ],
                backgroundBarColors: [],
                backgroundBarOpacity: 1,
                backgroundBarRadius: 0,
              },
            },
          },
          dataLabels: {
            enabled: false,
            enabledOnSeries: undefined,
          },
          labels: [],
          yaxis: {
            labels: {
              formatter: function (value) {
                return "₦" + numeral(value).format("0.00a");
              },
            },
          },
        },
        series: [
          {
            name: "Amount",
            data: [],
          },
        ],
      },
      ChannelChart: {
        options: {
          chart: {
            id: "vuechart-example2",
            type: "bar",
            animations: {
              enabled: true,
              easing: "easeinout",
              speed: 800,
              animateGradually: {
                enabled: true,
                delay: 150,
              },
              dynamicAnimation: {
                enabled: true,
                speed: 350,
              },
            },
          },
          theme: {
            palette: "palette1",
          },
          noData: {
            text: "Loading...",
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 10,
              borderRadiusApplication: "around",
              borderRadiusWhenStacked: "last",
              columnWidth: "70%",
              barHeight: "70%",
              distributed: false,
              rangeBarOverlap: true,
              rangeBarGroupRows: false,
              hideZeroBarsWhenGrouped: false,
              isDumbbell: false,
              dumbbellColors: undefined,
              isFunnel: false,
              isFunnel3d: true,
              colors: {
                ranges: [
                  {
                    from: 0,
                    to: 0,
                    color: undefined,
                  },
                ],
                backgroundBarColors: [],
                backgroundBarOpacity: 1,
                backgroundBarRadius: 0,
              },
            },
          },
          dataLabels: {
            enabled: false,
            enabledOnSeries: undefined,
          },
          labels: [],
          yaxis: {
            labels: {
              formatter: function (value) {
                return "₦" + numeral(value).format("0.00a");
              },
            },
          },
        },
        series: [
          {
            name: "Amount",
            data: [],
          },
        ],
      },
      RevenueItemChart: {
        options: {
          chart: {
            id: "vuechart-example",
            type: "bar",
            animations: {
              enabled: true,
              easing: "easeinout",
              speed: 800,
              animateGradually: {
                enabled: true,
                delay: 150,
              },
              dynamicAnimation: {
                enabled: true,
                speed: 350,
              },
            },
          },
          noData: {
            text: "Loading...",
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 10,
              borderRadiusApplication: "around",
              borderRadiusWhenStacked: "last",
              columnWidth: "70%",
              barHeight: "70%",
              distributed: false,
              rangeBarOverlap: true,
              rangeBarGroupRows: false,
              hideZeroBarsWhenGrouped: false,
              isDumbbell: false,
              dumbbellColors: undefined,
              isFunnel: false,
              isFunnel3d: true,
              colors: {
                ranges: [
                  {
                    from: 0,
                    to: 0,
                    color: undefined,
                  },
                ],
                backgroundBarColors: [],
                backgroundBarOpacity: 1,
                backgroundBarRadius: 0,
              },
            },
          },
          dataLabels: {
            enabled: false,
            enabledOnSeries: undefined,
          },
          labels: [],
          yaxis: {
            labels: {
              formatter: function (value) {
                return "₦" + numeral(value).format("0.00a");
              },
            },
          },
        },
        series: [
          {
            name: "Amount",
            data: [],
          },
        ],
      },
      BanksChart: {
        options: {
          chart: {
            id: "vuechart-example2",
            type: "bar",
            animations: {
              enabled: true,
              easing: "easeinout",
              speed: 800,
              animateGradually: {
                enabled: true,
                delay: 150,
              },
              dynamicAnimation: {
                enabled: true,
                speed: 350,
              },
            },
          },
          theme: {
            palette: "palette3",
          },
          noData: {
            text: "Loading...",
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 10,
              borderRadiusApplication: "around",
              borderRadiusWhenStacked: "last",
              columnWidth: "70%",
              barHeight: "70%",
              distributed: false,
              rangeBarOverlap: true,
              rangeBarGroupRows: false,
              hideZeroBarsWhenGrouped: false,
              isDumbbell: false,
              dumbbellColors: undefined,
              isFunnel: false,
              isFunnel3d: true,
              colors: {
                ranges: [
                  {
                    from: 0,
                    to: 0,
                    color: undefined,
                  },
                ],
                backgroundBarColors: [],
                backgroundBarOpacity: 1,
                backgroundBarRadius: 0,
              },
            },
          },
          dataLabels: {
            enabled: false,
            enabledOnSeries: undefined,
          },
          labels: [],
          yaxis: {
            labels: {
              formatter: function (value) {
                return "₦" + numeral(value).format("0.00a");
              },
            },
          },
        },
        series: [
          {
            name: "Amount",
            data: [],
          },
        ],
      },
    };
  },
  methods: {
    formatAmount,
    formatDates() {
      let dt = new Date();
      let dt2 = new Date();

      let date = {
        startDate: `${dt.getFullYear()}-${dt.getMonth() + 1}-1`,
        endDate: `${dt2.getFullYear()}-${dt2.getMonth() + 1}-${dt2.getDate()}`,
      };

      if (this.searchPaymentData.interval != undefined) {
        return {
          startDate: dayjs(this.searchPaymentData.startDate).format(
            "YYYY-MM-DD"
          ),
          endDate: dayjs(this.searchPaymentData.endDate).format("YYYY-MM-DD"),
        };
      } else
        return {
          startDate: dayjs(date.startDate).format("YYYY-MM-DD"),
          endDate: dayjs(date.endDate).format("YYYY-MM-DD"),
        };
    },
    getMDAsReportData() {
      this.$store
        .dispatch("report/getMDAsChartData", {
          reportType: "payment",
          ...this.formatDates(),
        })
        .then(() => {
          this.updateMDAsChart();
        })
        .catch((err) => console.log(err));
    },
    updateMDAsChart() {
      const result = this.$store.getters["report/mdaReportData"];

      let series = [];
      let labels = [];
      this.TotalPayments = 0;

      result.forEach((res) => {
        series.push(Math.round(res.Amount));
        this.TotalPayments += Math.round(res.Amount);
        labels.push(
          res.item.length > 20 ? res.item.substr(0, 20) + "..." : res.item
        );
      });

      this.MdaChart.series = [{ data: series }];
      this.MdaChart.options = {
        ...this.MdaChart.options,
        labels: labels,
      };
    },
    getChannelsReportData() {
      this.$store
        .dispatch("report/getChannelsChartData", {
          reportType: "payment",
          ...this.formatDates(),
        })
        .then(() => {
          this.updateChannelsChart();
        })
        .catch((err) => console.log(err));
    },
    updateChannelsChart() {
      const result = this.$store.getters["report/channelReportData"];
      let series = [];
      let labels = [];
      this.ChannelsTotalPayments = 0;

      result.forEach((res) => {
        series.push(Math.round(res.data.currYear.Amount));
        this.ChannelsTotalPayments += Math.round(res.data.currYear.Amount);

        labels.push(
          res.channel.length > 20
            ? res.channel.substr(0, 20) + "..."
            : res.channel
        );
      });

      this.ChannelChart.series = [{ data: series }];
      this.ChannelChart.options = {
        ...this.ChannelChart.options,
        labels: labels,
      };
    },
    getBanksReportData() {
      this.$store
        .dispatch("report/getBanksChartData", {
          reportType: "payment",
          bank: "bank",
          ...this.formatDates(),
        })
        .then(() => {
          this.updateBanksChart();
        })
        .catch((err) => console.log(err));
    },
    updateBanksChart() {
      const result = this.$store.getters["report/bankReportData"];
      let series = [];
      let labels = [];

      this.BanksTotalPayments = 0;

      result.forEach((res) => {
        series.push(Math.round(res.Amount));
        this.BanksTotalPayments += Math.round(res.Amount);
        labels.push(
          res.item.length > 20 ? res.item.substr(0, 20) + "..." : res.item
        );
      });

      this.BanksChart.series = [{ data: series, type: "bar" }];
      this.BanksChart.options = {
        ...this.BanksChart.options,
        labels: labels,
      };
    },
    getRevenueItemsReportData() {
      this.$store
        .dispatch("report/getRevenueItemsChartData", {
          reportType: "payment",
          ...this.formatDates(),
        })
        .then(() => {
          this.updateRevenueItemsChart();
        })
        .catch((err) => console.log(err));
    },
    updateRevenueItemsChart() {
      const result = this.$store.getters["report/revenueItemReportData"];
      let series = [];
      let labels = [];

      this.RevenueItemsTotalPayments = 0;

      result.forEach((res) => {
        series.push(Math.round(res.Amount));
        this.RevenueItemsTotalPayments += Math.round(res.Amount);
        labels.push(
          res.item.length > 20 ? res.item.substr(0, 23) + "..." : res.item
        );
      });

      this.RevenueItemChart.series = [{ data: series, type: "bar" }];
      this.RevenueItemChart.options = {
        ...this.RevenueItemChart.options,
        labels: labels,
      };
    },
  },
  beforeUnmount() {
    clearInterval(this.chartInterval);
    this.searchPaymentData = {};
  },
  created() {
    this.searchPaymentData = this.pSearchPaymentData;

    if (this.searchPaymentData.interval != undefined) {
      this.getMDAsReportData();
      this.getChannelsReportData();
      this.getBanksReportData();
      this.getRevenueItemsReportData();
    } else {
      this.getMDAsReportData();
      this.getChannelsReportData();
      this.getBanksReportData();
      this.getRevenueItemsReportData();
      this.chartInterval = setInterval(() => {
        this.getMDAsReportData();
        this.getChannelsReportData();
        this.getBanksReportData();
        this.getRevenueItemsReportData();
      }, 5000);
    }
  },
};
</script>

<style lang="scss" scoped></style>
